.how_it_works_wrap {
	background-color: #e7eeea;
	margin: 30px 0px;
}
.wrapper {
	display: flex;
	flex-direction: column !important;
	align-items: center;
	justify-content: center;
	width: 50%;
	text-align: center;
	margin-top: 50px;
}

.heading {
	font-style: normal;
	font-weight: 850;
	font-size: 2.5em;
	line-height: 44px;
	align-items: center;
	text-align: center;
	letter-spacing: 1.33304px;
	color: #245448;
}
.description {
	font-family: "Josefin Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 1.5em;
	line-height: 26px;
	text-align: center;
	letter-spacing: 0.2px;
	color: #4f5152;
}
