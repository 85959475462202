@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;300;400;500;600;700&family=Libre+Baskerville:wght@400;700&family=Roboto:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;300;400;500;600;700&display=swap");

.footerSection {
	background-image: url("/public/images/footerbg.png");
	width: 100%;
	/* height: 200px; */
	color: white;
	padding: 55px 0;
}

.footerSection h3 {
	font-family: Libre Baskerville;
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: normal;

	color: #ffffff;
	margin-bottom: 44px;
}

.footerSection ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.footerSection ul li {
	margin-bottom: 20px;
	font-family: "Josefin Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: normal;
	letter-spacing: 0.025em;
	color: #ffffff;
}
.contactUs ul li i {
	margin-right: 20px;
}
.contactUs li span {
	font-family: Josefin Sans;
}

.quickLinks ul li a {
	cursor: pointer;
	margin-bottom: 20px;
	font-family: "Josefin Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: normal;
	letter-spacing: 0.025em;
	color: #ffffff;
	text-decoration: none;
}
.quickLinks ul li a:hover {
	color: #029834;
}

ul.socialLinks {
	display: flex;
	flex-direction: column;
}

ul.socialLinks li {
	margin-right: 20px;
}

ul.socialLinks span {
	padding-left: 10px;
}

ul.socialLinks li a {
	color: #fff;
	text-decoration: none;
}
ul.socialLinks li a:hover i {
	color: #029834;
	text-decoration: none;
}

.footerBottom {
	width: 100%;
	background-color: white;
	color: black;
	text-align: center;
	padding: 10px 0;
}

.footerBottom p {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	margin: 0;
	line-height: 26px;
}

.footerBottom p i {
	font-size: 10px;
}

@media only screen and (max-width: 768px) {
	.footerSection {
		margin-top: 30px;
	}
}
