@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;300;400;500;600;700&family=Libre+Baskerville:wght@400;700&display=swap");
.body {
	/* background-color: #ddd; */
}
#home {
	/* background-color: #; */
}
.main_abot_wrap {
	/* box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6); */
}
.content {
	padding: 80px 20px 0px 0px;
}
.content h2 {
	font-style: normal;
	font-weight: 850;
	font-size: 40px;
	line-height: 44px;
	display: flex;
	align-items: center;
	letter-spacing: 1.33304px;
	color: #245448;
}
.content p {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	letter-spacing: 0.2px;
	color: #4f5152;
}
img.about_img {
	width: 100%;
}
.about_img {
	margin: 20px 0px;
	max-height: 450px;
	min-height: 350px;
}

@media only screen and (max-width: 768px) {
	.mid .mid_content {
		order: 2;
	}
	.mid .mid_about_img {
		order: 1;
	}
	.about {
		padding-top: 0px !important;
	}
	.content {
		padding: 0px !important;
	}

	.getintouch {
		padding: 0px;
	}

	.content h2 {
		font-size: 32px;
		line-height: normal;
	}
	.content p {
		line-height: normal;
		font-size: 16px;
	}
}
