.header {
	background: #2b2b2b;
	position: sticky;
	top: -20px;
	z-index: 1;
	box-shadow: 0px 3px 9px -16px rgba(51, 50, 50, 0.1);
}
.canvas_wrap {
	display: none;
}
.hamburger {
	display: none;
}
.logo_wrap {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}

.top_nav {
	/* background-color: #000; */
	/* box-shadow: 0px 0px 16.8471px 4.67975px rgba(0, 0, 0, 0.22); */
}
.main_nav {
	padding: 10px 0px;
}

.top_nav ul {
	display: flex;
	margin: 0px;
	padding: 5px 0px;
	justify-content: flex-end;
}
.top_nav ul li {
	margin-left: 20px;
	list-style: none;
	font-family: "Josefin Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.025em;
	color: #ffffff;
}
.top_nav ul li a {
	color: #fff;
}
.top_nav ul li a:hover {
	color: #029834;
}
.top_nav ul li span {
	padding-left: 10px;
}
.nav_bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0px 12px 0px;
	margin: 0px;
	height: 40px;
}
ul.nav_bar li {
	list-style: none;
}
ul.nav_bar li a.link {
	font-family: "Josefin Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.02em;
	color: #ffffff;
	text-decoration: none;
}
ul.nav_bar li a.comingSoon {
	color: rgb(235, 141, 0);
	text-decoration: none;
	font-family: "Josefin Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.02em;
}
ul.nav_bar li .link:hover {
	color: #029834;
	border-bottom: 1px solid #029834;
	cursor: pointer;
}
ul.nav_bar li link:visited {
	border-bottom: 1px solid #029834;
}

@media only screen and (max-width: 991px) {
	.nav_wrapper {
		display: flex;
		justify-content: flex-end;
	}
	.nav_wrapper .nav_bar {
		display: none;
	}
	.hamburger {
		display: block;
		z-index: 2;
	}
	.canvas_wrap h2 {
		text-align: center;
		margin-top: 40px;
		color: #fff;
	}
	.canvas_wrap ul.nav_bar {
		align-items: flex-start;
		margin: 0px;
		padding: 0px;
	}
	.canvas_wrap ul.nav_bar li {
		width: 100%;
		padding-left: 40px;
		align-items: flex-start;
		padding-left: 20px;
		margin: 10px 0px;
		border-bottom: 1px solid #383838;
	}
	.canvas_wrap .c_social {
		display: flex;
		justify-content: space-around;
	}
	.canvas_wrap .c_social li {
		border: none !important;
		color: #fff;
	}
	.hamburger a {
		color: #fff;
		background-color: transparent;
		border: none;
		font-size: 26px;
	}
	.canvas_wrap {
		display: block;
	}
	.offcanvas {
		background-color: #333;
		width: 248px;
	}
	.canvas_wrap ul {
		display: flex;
		flex-direction: column;
	}
}

@media only screen and (max-width: 768) {
	.top_nav ul {
		display: none;
	}
}
@media only screen and (max-width: 490px) {
	.top_nav {
		display: none;
	}
}
