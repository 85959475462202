.coming-soon {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin-top: 10px;
 
}
.top {
  display: flex;
  flex-direction: column;
  height: 50%;
  border-bottom: 1px solid rgb(236, 236, 236);
  padding-bottom: 10px;
}
.heading {
flex: 1;
margin-top: 20px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 2;
  height: min-content;
}
.layout{
    border: 0.1px solid rgb(228, 228, 228);
    margin: 4px;
    background-color: rgb(247, 247, 247);
}


.bottom {
flex: 1;
}
