*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

body {
  overflow-x: hidden;
  height: 100vh;
}


