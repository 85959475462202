@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;300;400;500;600;700&family=Libre+Baskerville:wght@400;700&display=swap');

.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center ;

}
.project_image{
    max-width: 100%;
    height: 350px;
    margin-top: 40px;
}
.project_detail{
   width: 100%;
   padding: 20px;
}
.project_detail h2{
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 1px;
    color: #245448;
}

.project_detail p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #4F5152;
}