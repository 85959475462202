.description{
font-family: 'Josefin Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 26px;
text-align: center;
letter-spacing: 0.2px;

}
.how-it-work-card{
  height:max-content;
  text-align: center;
  padding: 0.8em; 
}
.title{
font-family: 'Caecilia LT Pro';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 32px;
text-align: center;
letter-spacing: 0.2px;
color: #245448;
}

.card-image {
  height: auto;
  max-width: 100%;
}