.product-card {
	margin: 10px 0px;
	display: flex;
	padding: 0px;
	height: 100%;
	flex-direction: column;
	align-content: center;
	border: 0.1rem solid rgb(209, 209, 209);
	border-radius: 0.313rem;
	box-shadow: 0px 0px 2px 1px rgba(227, 227, 227, 0.75);
	-webkit-box-shadow: 0px 0px 2px 1px rgba(227, 227, 227, 0.75);
	-moz-box-shadow: 0px 0px 2px 1px rgba(227, 227, 227, 0.75);
}
.image-wrapper {
	height: 80%;
}
.card-box {
	margin: 10px 0px;
}
.product-image {
	width: 100%;
	height: 100%;
	padding: 0px;
	border-bottom: 0.1rem solid rgb(212, 212, 212);
}

.detail {
	display: flex;
	background-color: white;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 20%;
}
.title {
	font-family: "Caecilia LT Pro";
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 2rem;
	text-align: center;
	letter-spacing: 0.013rem;
	color: #245448;
	margin-top: 1.25rem;
}
.description {
	font-family: "Josefin Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.625rem;
	text-align: center;
	letter-spacing: 0.013rem;
	color: #4f5152;
	width: 100%;
}

@media only screen and (max-width: 991px) {
	.title {
		font-size: 14px !important;
	}
}
