.banner_img{
  width: 100%;
}
.banner_img img{
  width:100%;
  object-fit: cover;
}
.slick-next:before{
  display: none !important;
}
.slick-arrow{
  display: none !important;
}
.slick-prev{
  display: none !important;
} .slick-next{
  display: none !important;
}
.slick-slide img{
  width: 100% !important;
}

.slick-slide {
  margin: 0;
}
/* the parent */
.slick-list {
  margin: 0;
}
.slick-cloned{
  /* width: 100% !important; */
}